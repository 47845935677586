<template>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
    <div class="container-fluid shadow-sm">
      <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3 navbar-offcanvas">
        <router-link
            to="/jual/lisensi"
            class="btn p-0 mr-md-4 text-light h5 mb-0"
          >
            <i class="fas fa-arrow-left"></i>
          </router-link>
        <span class="navbar-brand bg-transparent rounded p-0 text-center mr-0 mr-md-5">
          <span class="h6 font-weight-bold text-truncate">Notifikasi</span>
        </span>
        <Menu>
          <ul class="navbar-nav w-100 pl-3 pl-md-0">
            <li class="nav-item mt-1 mb-n1 active">
              <router-link
                to="/jual/lisensi"
                class="nav-link bg-yellowish text-main"
                title="Lisensi (Free trial 5 hari lagi)"
                >Lisensi<span class="d-md-none">
                  (Free trial 5 hari lagi)</span
                ></router-link>
            </li>
            <li class="nav-item mt-1 mb-n1">
              <router-link to="/jual/bantuan" class="nav-link" title="Chat"
                >Bantuan</router-link>
            </li>
            <li class="nav-item mt-1 mb-n1 active">
              <router-link to="/jual/notifikasi" class="nav-link" title="Notifikasi">Notifikasi <span class="badge badge-pill badge-secondary align-top">1</span></router-link>
            </li>
          </ul>
        </Menu>
      </nav>
    </div><!-- .container -->
  </header>

  <div class="container-fluid mt-header">
    <div class="row" id="body-sidemenu">
      <!-- MAIN -->
      <div id="main-content" class="col with-fixed-sidebar">

        <div class="row mt-3">
          <div class="col-12">
            <div class="alert alert-danger bg-light alert-dismissible fade show" role="alert">
              <p class="small text-muted my-1">21 Mei 2021</p>
              <button type="button" class="close" data-dismiss="alert">&times;</button>
              <p class="mb-2"><strong>5 hari lagi</strong> masa trial 30-hari akan berakhir. Silakan masukkan kode lisensi atau kode promo untuk melanjutkan.</p>
              <a href="" class="btn btn-sm btn-light border py-0 mt-0" type="submit" title="Aktivasi..."><i class="fab fa-telegram-plane mr-1"></i>Aktivasi</a>
            </div>
          </div> <!-- .col -->
          <div class="col-12">
            <div class="alert alert-secondary bg-light alert-dismissible fade show" role="alert">
              <p class="small text-muted my-1">21 Mei 2021</p>
              <button type="button" class="close" data-dismiss="alert">&times;</button>
              <p class="mb-2">Lorem ipsum dolor sit amet.</p>
              <a href="" class="btn btn-sm btn-light border py-0 mt-0" type="submit" title="Action..."><i class="fab fa-telegram-plane mr-1"></i>Action</a>
            </div>
          </div> <!-- .col -->
        </div> <!-- .row -->

        <div class="row mt-3 mb-5">
          <div class="col-12">
          </div><!-- .col -->
        </div><!-- .row -->
  
      </div><!-- main-content -->
    </div><!-- .row -->
  </div><!-- .container -->
</template>
<script>
import Menu from "../../components/menu/Menu.vue";
export default {
  setup() {},
  components: {
    Menu,
  },
};
</script>